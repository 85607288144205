/**
 * This file was automatically generated by cosmwasm-typescript-gen@0.3.7.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run the cosmwasm-typescript-gen generate command to regenerate this file.
 */

import { Coin, StdFee } from '@cosmjs/amino'
import {
  CosmWasmClient,
  ExecuteResult,
  SigningCosmWasmClient,
} from '@cosmjs/cosmwasm-stargate'
export type Uint128 = string
export type Expiration =
  | {
      at_height: number
    }
  | {
      at_time: Timestamp
    }
  | {
      never: {
        [k: string]: unknown
      }
    }
export type Timestamp = Uint64
export type Uint64 = string
export interface ClaimsResponse {
  claims: Claim[]
  [k: string]: unknown
}
export interface Claim {
  amount: Uint128
  release_at: Expiration
  [k: string]: unknown
}
export type DaoResponse = string
export type ExecuteMsg =
  | {
      stake: {
        [k: string]: unknown
      }
    }
  | {
      unstake: {
        amount: Uint128
        [k: string]: unknown
      }
    }
  | {
      update_config: {
        duration?: Duration | null
        manager?: string | null
        owner?: string | null
        [k: string]: unknown
      }
    }
  | {
      claim: {
        [k: string]: unknown
      }
    }
export type Duration =
  | {
      height: number
    }
  | {
      time: number
    }
export type Addr = string
export interface GetConfigResponse {
  denom: string
  manager?: Addr | null
  owner?: Addr | null
  unstaking_duration?: Duration | null
  [k: string]: unknown
}
export interface InfoResponse {
  info: ContractVersion
  [k: string]: unknown
}
export interface ContractVersion {
  contract: string
  version: string
  [k: string]: unknown
}
export interface InstantiateMsg {
  denom: string
  manager?: string | null
  owner?: string | null
  unstaking_duration?: Duration | null
  [k: string]: unknown
}
export interface IsActiveResponse {
  active: boolean
  [k: string]: unknown
}
export interface MigrateMsg {
  [k: string]: unknown
}
export type QueryMsg =
  | {
      dao: {
        [k: string]: unknown
      }
    }
  | {
      get_config: {
        [k: string]: unknown
      }
    }
  | {
      claims: {
        address: string
        [k: string]: unknown
      }
    }
  | {
      voting_power_at_height: {
        address: string
        height?: number | null
        [k: string]: unknown
      }
    }
  | {
      total_power_at_height: {
        height?: number | null
        [k: string]: unknown
      }
    }
  | {
      info: {
        [k: string]: unknown
      }
    }
export interface TotalPowerAtHeightResponse {
  height: number
  power: Uint128
  [k: string]: unknown
}
export interface VotingPowerAtHeightResponse {
  height: number
  power: Uint128
  [k: string]: unknown
}
export interface CwNativeStakedBalanceVotingReadOnlyInterface {
  contractAddress: string
  dao: () => Promise<DaoResponse>
  getConfig: () => Promise<GetConfigResponse>
  claims: ({ address }: { address: string }) => Promise<ClaimsResponse>
  votingPowerAtHeight: ({
    address,
    height,
  }: {
    address: string
    height?: number
  }) => Promise<VotingPowerAtHeightResponse>
  totalPowerAtHeight: ({
    height,
  }: {
    height?: number
  }) => Promise<TotalPowerAtHeightResponse>
  info: () => Promise<InfoResponse>
}
export class CwNativeStakedBalanceVotingQueryClient
  implements CwNativeStakedBalanceVotingReadOnlyInterface
{
  client: CosmWasmClient
  contractAddress: string

  constructor(client: CosmWasmClient, contractAddress: string) {
    this.client = client
    this.contractAddress = contractAddress
    this.dao = this.dao.bind(this)
    this.getConfig = this.getConfig.bind(this)
    this.claims = this.claims.bind(this)
    this.votingPowerAtHeight = this.votingPowerAtHeight.bind(this)
    this.totalPowerAtHeight = this.totalPowerAtHeight.bind(this)
    this.info = this.info.bind(this)
  }

  dao = async (): Promise<DaoResponse> => {
    return this.client.queryContractSmart(this.contractAddress, {
      dao: {},
    })
  }
  getConfig = async (): Promise<GetConfigResponse> => {
    return this.client.queryContractSmart(this.contractAddress, {
      get_config: {},
    })
  }
  claims = async ({
    address,
  }: {
    address: string
  }): Promise<ClaimsResponse> => {
    return this.client.queryContractSmart(this.contractAddress, {
      claims: {
        address,
      },
    })
  }
  votingPowerAtHeight = async ({
    address,
    height,
  }: {
    address: string
    height?: number
  }): Promise<VotingPowerAtHeightResponse> => {
    return this.client.queryContractSmart(this.contractAddress, {
      voting_power_at_height: {
        address,
        height,
      },
    })
  }
  totalPowerAtHeight = async ({
    height,
  }: {
    height?: number
  }): Promise<TotalPowerAtHeightResponse> => {
    return this.client.queryContractSmart(this.contractAddress, {
      total_power_at_height: {
        height,
      },
    })
  }
  info = async (): Promise<InfoResponse> => {
    return this.client.queryContractSmart(this.contractAddress, {
      info: {},
    })
  }
}
export interface CwNativeStakedBalanceVotingInterface
  extends CwNativeStakedBalanceVotingReadOnlyInterface {
  contractAddress: string
  sender: string
  stake: (
    fee?: number | StdFee | 'auto',
    memo?: string,
    funds?: readonly Coin[]
  ) => Promise<ExecuteResult>
  unstake: (
    {
      amount,
    }: {
      amount: string
    },
    fee?: number | StdFee | 'auto',
    memo?: string,
    funds?: readonly Coin[]
  ) => Promise<ExecuteResult>
  updateConfig: (
    {
      duration,
      manager,
      owner,
    }: {
      duration?: Duration
      manager?: string
      owner?: string
    },
    fee?: number | StdFee | 'auto',
    memo?: string,
    funds?: readonly Coin[]
  ) => Promise<ExecuteResult>
  claim: (
    fee?: number | StdFee | 'auto',
    memo?: string,
    funds?: readonly Coin[]
  ) => Promise<ExecuteResult>
}
export class CwNativeStakedBalanceVotingClient
  extends CwNativeStakedBalanceVotingQueryClient
  implements CwNativeStakedBalanceVotingInterface
{
  client: SigningCosmWasmClient
  sender: string
  contractAddress: string

  constructor(
    client: SigningCosmWasmClient,
    sender: string,
    contractAddress: string
  ) {
    super(client, contractAddress)
    this.client = client
    this.sender = sender
    this.contractAddress = contractAddress
    this.stake = this.stake.bind(this)
    this.unstake = this.unstake.bind(this)
    this.updateConfig = this.updateConfig.bind(this)
    this.claim = this.claim.bind(this)
  }

  stake = async (
    fee: number | StdFee | 'auto' = 'auto',
    memo?: string,
    funds?: readonly Coin[]
  ): Promise<ExecuteResult> => {
    return await this.client.execute(
      this.sender,
      this.contractAddress,
      {
        stake: {},
      },
      fee,
      memo,
      funds
    )
  }
  unstake = async (
    {
      amount,
    }: {
      amount: string
    },
    fee: number | StdFee | 'auto' = 'auto',
    memo?: string,
    funds?: readonly Coin[]
  ): Promise<ExecuteResult> => {
    return await this.client.execute(
      this.sender,
      this.contractAddress,
      {
        unstake: {
          amount,
        },
      },
      fee,
      memo,
      funds
    )
  }
  updateConfig = async (
    {
      duration,
      manager,
      owner,
    }: {
      duration?: Duration
      manager?: string
      owner?: string
    },
    fee: number | StdFee | 'auto' = 'auto',
    memo?: string,
    funds?: readonly Coin[]
  ): Promise<ExecuteResult> => {
    return await this.client.execute(
      this.sender,
      this.contractAddress,
      {
        update_config: {
          duration,
          manager,
          owner,
        },
      },
      fee,
      memo,
      funds
    )
  }
  claim = async (
    fee: number | StdFee | 'auto' = 'auto',
    memo?: string,
    funds?: readonly Coin[]
  ): Promise<ExecuteResult> => {
    return await this.client.execute(
      this.sender,
      this.contractAddress,
      {
        claim: {},
      },
      fee,
      memo,
      funds
    )
  }
}
