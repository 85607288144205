/**
 * This file was automatically generated by cosmwasm-typescript-gen@0.3.4.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run the cosmwasm-typescript-gen generate command to regenerate this file.
 */

import { Coin, StdFee } from '@cosmjs/amino'
import { ExecuteResult, SigningCosmWasmClient } from '@cosmjs/cosmwasm-stargate'
export type ExecuteMsg = {
  instantiate_contract_with_self_admin: {
    code_id: number
    instantiate_msg: Binary
    label: string
    [k: string]: unknown
  }
}
export type Binary = string
export interface InstantiateMsg {
  [k: string]: unknown
}
export interface CwAdminFactoryInterface {
  contractAddress: string
  sender: string
  instantiateContractWithSelfAdmin: (
    {
      codeId,
      instantiateMsg,
      label,
    }: {
      codeId: number
      instantiateMsg: string
      label: string
    },
    fee?: number | StdFee | 'auto',
    memo?: string,
    funds?: readonly Coin[]
  ) => Promise<ExecuteResult>
}
export class CwAdminFactoryClient implements CwAdminFactoryInterface {
  client: SigningCosmWasmClient
  sender: string
  contractAddress: string

  constructor(
    client: SigningCosmWasmClient,
    sender: string,
    contractAddress: string
  ) {
    this.client = client
    this.sender = sender
    this.contractAddress = contractAddress
    this.instantiateContractWithSelfAdmin =
      this.instantiateContractWithSelfAdmin.bind(this)
  }

  instantiateContractWithSelfAdmin = async (
    {
      codeId,
      instantiateMsg,
      label,
    }: {
      codeId: number
      instantiateMsg: string
      label: string
    },
    fee: number | StdFee | 'auto' = 'auto',
    memo?: string,
    funds?: readonly Coin[]
  ): Promise<ExecuteResult> => {
    return await this.client.execute(
      this.sender,
      this.contractAddress,
      {
        instantiate_contract_with_self_admin: {
          code_id: codeId,
          instantiate_msg: instantiateMsg,
          label,
        },
      },
      fee,
      memo,
      funds
    )
  }
}
